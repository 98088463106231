import * as api from "./adapter";

export default {
  /**
   * getManagers
   * @returns {Promise<*>}
   */
  index: ({ id, type }) =>
    api.read("external_object_versions", {
      query: {
        filter: {
          item_type: type,
          item_id: id,
        },
        page: { size: 999 },
      },
    }),
  /**
   * getManagers
   * @returns {Promise<*>}
   */
  getItems: (id) =>
    api.read(`external_object_versions/get_applicant_with_experience/${id}`),
};
