import get from "lodash/get";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { matchPath } from "react-router-dom";
import { LOCATION_CHANGE, getLocation } from "connected-react-router";
import { actions, constants, selector, routeConfig } from "./redux";
import { addItem as notify } from "../notifications/actions";
import Api from "../../utils/Api";
import { didDocumentLocationChange } from "../../utils/router";
import { addSuccess, addError } from "../notifications/actions";
import { stopSubmit } from "redux-form";

export function* fetchItem() {
  try {
    const { pathname } = yield select(getLocation);
    const {
      params: { id },
    } = matchPath(pathname, routeConfig);
    const featureState = yield select(selector);
    const data = yield call(Api.vessels.show, id, featureState);

    yield put(actions.addLoadedItem(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemError(e));
  }
}

export function* fetchItemsPageInit(action) {
  const match = didDocumentLocationChange(action, routeConfig);

  if (match) {
    yield put(actions.loadItemRequest(match.params.id));
  }
}

export function* updatePhoto() {
  try {
    yield put(actions.setPhotoIsLoading(true));
    const { id } = yield select((s) => s.vesselDetails.data);
    yield call(Api.vessels.updatePhoto, { id });

    yield put(addSuccess('Photo updated successfully'));
    yield put(actions.loadItemRequest());
    yield put(actions.setPhotoIsLoading(false));
  } catch (error) {
    yield put(actions.loadItemsError(error));
  }
}

function* setManager({ payload }) {
  try {
    const { id: vesselId, manager_vessel } = yield select(
      (s) => s.vesselDetails.data
    );

    if (manager_vessel && payload) {
      yield call(Api.vessels.changeManager, manager_vessel.id, payload.value);
    } else if (!manager_vessel && payload) {
      yield call(Api.vessels.setManager, vesselId, payload.value);
    } else if (!payload && manager_vessel.id) {
      yield call(Api.vessels.deleteManager, manager_vessel.id);
    } else {
      throw new Error("Internal front-end error #666");
    }

    yield put(actions.managerChangedSuccessfully());
    yield put(
      notify({
        type: "success",
        title: "Success",
        message: payload
          ? `${payload.label} is set as manager successfully`
          : "The manager deleted successfully",
      })
    );
  } catch (e) {
    console.error(e);
  }
}

function* setOwner({ payload }) {
  try {
    const { id: vesselId, owner_vessel } = yield select(
      (s) => s.vesselDetails.data
    );

    if (owner_vessel && payload) {
      yield call(Api.vessels.changeOwner, owner_vessel.id, payload.value);
    } else if (!owner_vessel && payload) {
      yield call(Api.vessels.setOwner, vesselId, payload.value);
    } else if (!payload && owner_vessel.id) {
      yield call(Api.vessels.deleteOwner, owner_vessel.id);
    } else {
      throw new Error("Internal front-end error #999");
    }

    yield put(actions.ownerChangedSuccessfully());
    yield put(
      notify({
        type: "success",
        title: "Success",
        message: payload
          ? `${payload.label} is set as owner successfully`
          : "The owner deleted successfully",
      })
    );
  } catch (e) {
    console.error(e);
  }
}

function* changeCoordinator({ payload }) {
  try {
    const { id: vesselId } = yield select((s) => s.vesselDetails.data);
    const { label: coordinatorName, value: coordinator_id, relation } = payload;
    const data = { id: coordinator_id, type: "clients" };

    yield call(Api.vessels.updateRelation, vesselId, relation, data);

    yield put(actions.loadItemRequest());

    yield put(
      notify({
        type: "success",
        title: "Success",
        message: coordinatorName
          ? `${coordinatorName} is set as Crew Coordinator successfully`
          : "Crew Coordinator deleted successfully",
      })
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchItemFromEquasis() {
  try {
    yield put(actions.setEquasisLoading(true));
    const { pathname } = yield select(getLocation);
    const {
      params: { id },
    } = matchPath(pathname, { path: "/vessels/:id/:tab" });
    yield call(Api.vessels.update, id, { update_from_equasis: true, updated_at: new Date() });
    yield put(
      addSuccess('Vessel data from "Equasis.org" updated successfully')
    );
    yield put(actions.loadItemRequest());
    yield put(actions.setEquasisLoading(false));
  } catch (e) {
    yield put(actions.setEquasisLoading(false));
    if (get(e, "apiErrors[0].status") === "500") {
      yield put(
        addError("Updating failed. Equasis login or password is wrong.")
      );
    } else {
      yield put(actions.loadItemsError(e));
    }
    console.error(e);

    if (e.errorByField) yield put(stopSubmit("vessels", e.errorByField));
  }
}

/**
 * getFeatureSagas
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(
      [
        constants.LOAD_ITEM_REQUEST,
        constants.MANAGER_CHANGED_SUCCESSFULLY,
        constants.OWNER_CHANGED_SUCCESSFULLY,
      ],
      fetchItem
    ),
    takeEvery(constants.START_CHANGING_MANAGER, setManager),
    takeEvery(constants.START_CHANGING_OWNER, setOwner),
    takeEvery(LOCATION_CHANGE, fetchItemsPageInit),
    takeEvery(constants.FETCH_FROM_EQUASIS, fetchItemFromEquasis),
    takeEvery(constants.CHANGE_COORDINATOR, changeCoordinator),
    takeEvery(constants.UPDATE_PHOTO, updatePhoto),
  ];
}
