import authResourceApi from './auth';
import ranksResourceApi from './ranks';
import vesselResourceApi from './vessels';
import userResourceApi from './users';
import portResourceApi from './ports';
import ownerResourceApi from './owners';
import managerResourceApi from './managers';
import crewUserResourceApi from './crewUsers';
import timeplansResourceApi from './timeplans';
import selectedVesselResourceApi from './assignedVessels';
import selectedOwnerResourceApi from './assignedOwners';
import crewingStatuses from './crewingStatuses';
import engineTypes from './engineTypes';
import engineManufacturers from './engineManufacturers';
import comments from './comments';
import manningScales from './manningScales';
import crewList from './crewList';
import crewChange from './crewChange';
import userExperiences from './userExperiences';
import vesselExperiences from './vesselExperiences';
import vacancies from './vacancies';
import vesselTypes from './vesselTypes';
import vendorTypes from './vendorTypes';
import vendors from './vendors';
import certificateTypes from './certificateTypes';
import crewingRelationTypes from './crewingRelationTypes';
import userAttachments from './userAttachments';
import userCertificates from './userCertificates';
import userRelatives from './userRelatives';
import countries from './countries';
import mainEngines from './mainEngines';
import applicantContracts from './applicantContracts';
import applicantDocuments from './userDocuments';
import applicantInterviews from './userInterviews';
import englishTestTypes from './englishTestTypes';
import userEnglishTests from './userEnglishTests';
import userRecommendations from './userRecommendations';
import selectOptionsApi from './selectOptions';
import equasisVesselSearchApi from './equasisVesselSearch';
import crewingApplicants from './crewingApplicants';
import crewisorPositionCommentTypes from './crewisorPositionCommentTypes';
import commentEvents from './commentEvents';
import roleLevels from './roleLevels';
import favorites from './favorites';
import experienceVerifications from './userExperienceVerifications';
import appFormLinks from './appFormLinks';
import crewWages from './crewWages';
import performances from './performances';
import taskManager from './taskManager';
import ownerDocumentMatrix from './ownerDocumentMatrix';
import ownerEquipmentMatrix from './ownerEquipmentMatrix';
import vacancyApplies from './vacancyApplies';
import notificationManager from './notificationManager';
import surveyCreator from './surveyCreator';
import permissions from './permissions';
import externalObjectsHistory from './externalObjectsHistory';

export default {
  test: { read: () => [] }, // for testing
  auth: authResourceApi,
  vessels: vesselResourceApi,
  assignedVessels: selectedVesselResourceApi,
  assignedOwners: selectedOwnerResourceApi,
  ranks: ranksResourceApi,
  selectOptions: selectOptionsApi,
  timeplans: timeplansResourceApi,
  users: userResourceApi,
  ports: portResourceApi,
  owner: ownerResourceApi,
  manager: managerResourceApi,
  crewUser: crewUserResourceApi,
  crewingStatuses,
  engineTypes,
  engineManufacturers,
  comments,
  manningScales,
  crewList,
  crewChange,
  userExperiences,
  vesselExperiences,
  vacancies,
  vesselTypes,
  vendorTypes,
  vendors,
  certificateTypes,
  crewingRelationTypes,
  countries,
  userAttachments,
  userCertificates,
  userRelatives,
  mainEngines,
  applicantContracts,
  applicantDocuments,
  applicantInterviews,
  englishTestTypes,
  userEnglishTests,
  userRecommendations,
  equasisVesselSearch: equasisVesselSearchApi,
  crewingApplicants,
  crewisorPositionCommentTypes,
  commentEvents,
  roleLevels,
  favorites,
  experienceVerifications,
  externalObjectsHistory,
  appFormLinks,
  crewWages,
  performances,
  taskManager,
  ownerDocumentMatrix,
  ownerEquipmentMatrix,
  vacancyApplies,
  notificationManager,
  surveyCreator,
  permissions,
};
