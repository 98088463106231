import { createAction, handleActions } from "redux-actions";
import getConstantsByFeatureName from "../../utils/redux/getConstantsByFeatureName";
import getActionCreators from "../../utils/redux/getActionCreators";
import getActionHandlers from "../../utils/redux/getActionHandlers";
import { initialState as baseInitState } from "../../utils/redux/commonActionHandlers";

const initialState = {
  ...baseInitState,
  itemsList: [],
  selectedItem: null,
  versions: [],
  selectedSource: null,
  selectedDate: null,
};

const baseConstants = getConstantsByFeatureName("EXTERNAL_OBJECTS_HISTORY");
export const constants = {
  ...baseConstants,
  FETCH_ITEMS_REQUEST: "@EXTERNAL_OBJECTS_HISTORY/FETCH_ITEMS_REQUEST",
  FETCH_VERSIONS_REQUEST: "@EXTERNAL_OBJECTS_HISTORY/FETCH_VERSIONS_REQUEST",
  SET_ITEMS: "@EXTERNAL_OBJECTS_HISTORY/SET_ITEMS",
  SET_SELECTED_ITEM: "@EXTERNAL_OBJECTS_HISTORY/SET_SELECTED_ITEM",
  SET_VERSIONS: "@EXTERNAL_OBJECTS_HISTORY/SET_VERSIONS",
  SET_SELECTED_SOURCE: "@EXTERNAL_OBJECTS_HISTORY/SET_SELECTED_SOURCE",
  SET_SELECTED_DATE: "@EXTERNAL_OBJECTS_HISTORY/SET_SELECTED_DATE",
};

const baseActions = getActionCreators(constants);
export const actions = {
  ...baseActions,
  fetchItems: createAction(constants.FETCH_ITEMS_REQUEST),
  fetchVersions: createAction(constants.FETCH_VERSIONS_REQUEST),
  setItemsList: createAction(constants.SET_ITEMS),
  setSelectedItem: createAction(constants.SET_SELECTED_ITEM),
  setVersions: createAction(constants.SET_VERSIONS),
  setSelectedSource: createAction(constants.SET_SELECTED_SOURCE),
  setSelectedDate: createAction(constants.SET_SELECTED_DATE),
};

export const actionHandlers = getActionHandlers(constants);

export const reducer = handleActions(
  {
    ...actionHandlers,
    [constants.SET_ITEMS]: (state, action) => ({
      ...state,
      itemsList: action.payload,
    }),
    [constants.SET_SELECTED_ITEM]: (state, action) => ({
      ...state,
      selectedItem: action.payload,
    }),
    [constants.SET_VERSIONS]: (state, action) => ({
      ...state,
      versions: action.payload,
    }),
    [constants.SET_SELECTED_SOURCE]: (state, action) => ({
      ...state,
      selectedSource: action.payload,
    }),
    [constants.SET_SELECTED_DATE]: (state, action) => ({
      ...state,
      selectedDate: action.payload,
    }),
  },
  initialState
);

export const selector = (state) => ({ ...state.externalObjectsHistory });
