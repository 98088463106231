import { call, put, takeEvery } from "redux-saga/effects";
import { actions, constants } from "./redux";
import Api from "../../utils/Api";

export function* fetchItems({ payload: { id } }) {
  try {
    const { data } = yield call(Api.externalObjectsHistory.getItems, id);

    yield put(actions.setItemsList(data));
  } catch (e) {
    console.error(e);
    yield put(actions.loadItemsError(e));
  }
}

export function* fetchVersions({ payload: { id, type } }) {
  try {
    const response = yield call(Api.externalObjectsHistory.index, {
      id,
      type,
    });
    const versions = response?.data?.data || [];
    yield put(actions.setVersions(versions));
  } catch (e) {
    console.error(e);
    yield put(actions.loadItemsError(e));
  }
}

export default function getFeatureSagas() {
  return [
    takeEvery(constants.FETCH_ITEMS_REQUEST, fetchItems),
    takeEvery(constants.FETCH_VERSIONS_REQUEST, fetchVersions),
  ];
}
